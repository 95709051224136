import Amplify, { Cache, API, graphqlOperation } from 'aws-amplify';


async function getRecentVideos() {

  const search = `query SearchVideos {searchVideos(
        filter:{
          primaryAuthor:{
            exists:true
          }
           videoState: {
           matchPhrase: "ACTIVE"
          }
          audience:{
            matchPhrase:"Public"
          }
           includeInClipsSearch:{
            eq: true
          }
        }
        limit: 20
        sort:{
        field: createdAt
        direction: desc
      })
        {
          items{
            id
            title
            description
            thumbnail
            videoURL
             captions{
              language
              file
              type
            }
            allowTrainerDownload
            createdAt
            audience
             allowPublicAccess 
            videoViews
            videoState
            videoDuration
            primaryAuthor
            secondaryAuthor
            expirationDate
            captions{
              file
            }
            channels{
            items {
           channel{
                        id
                        title
                        description
                      }
             }
            }
          }
        }
      }`;

  const searchResult = await API.graphql(
    graphqlOperation(search)
  );

  return searchResult
}

export default getRecentVideos;
