import React, { useState, useEffect, Fragment } from "react";
import { Auth } from 'aws-amplify';
import VideoCard from '../VideoCard.js';
import '../videopage/VideoItem.css';
import Age from '../../functions/Age.js';
import VideoCardRowHeader from './VideoCardRowHeader.js';
import * as Queries from '../../graphql/queries.js'
import Amplify, { Cache, API, graphqlOperation } from 'aws-amplify';
import * as utils from '../../functions/util.js';
import queryString from 'query-string'
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';
import getTrendingVideos from '../../functions/getTrendingVideos.js';
import getRecentVideos from '../../functions/getRecentVideos.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom'

var isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
// Chrome 1 - 71
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isFirefox = typeof InstallTrigger !== 'undefined';

const copyVideoID = (id) => {
  navigator.clipboard.writeText(id)
  if (isChrome || isFirefox) navigator.clipboard.writeText(id)
  else if (isIE) {
    navigator.clipboard.writeText(id)

    window.clipboardData.setData('Text', id);
    document.execCommand('copy');
  }

}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function VideoCardRow(props) {
  console.log(props)
  const [Videos, setPlaylist] = useState(null);
  const [rerender, renderthis] = useState(false);
  const [dest, setDest] = useState();
  //const userInfo = Cache.getItem('userInfo')
    //const userInfo = props.user
  //console.log(userInfo)
 // let userInfo
  let myID 
  let videoMenu = []


  const [vidID, setVidID] = useState(null);
  const [finishLoading, setFinish] = useState();
  const [archiveOpen, setArchiveOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [admin, setAdmin] = React.useState(false);
  const [associatedList, setAssociatedList] = useState({playlist:[],channel:[]});
 const [userInfo, setUser] = useState();
  // const isDynamcic = props.dynamic;

  useEffect(() => {
    let data, func
      
   Auth.currentAuthenticatedUser({

    }).then(user => {
     
    utils.getUserDetailByID2(user.username).then(user=>{
  
       //userInfo= user.data.searchUsers.items[0]
      setUser(user.data.searchUsers.items[0])
            setFinish(true)
     })
    })
     
   
   // userInfo = Cache.getItem('userInfo')
     console.log(props)
    switch (props.func) {
      case "trending":
        func = utils.getTrending;
        setDest("/Trending")
        break;
      case "recent":
        func = getRecentVideos;
        setDest("/Recent")
        break;

      case "recommendations":
        func = utils.getRecommendation;
        setDest("/Recommendation")
        break;

      default:
    }

    func().then(function(result) {

      switch (props.func) {
        case "trending":
          data = JSON.parse(result)
          break;
        case "recent":
          data = result.data.searchVideos.items
          break;
        case "recommendations":

          data = JSON.parse(result)
          break;
        default:
      }


      let VideosArray = [];
      let playlistItem = data
      let numloaded = 0;
      console.log(data)
      for (var value of playlistItem) {

        var obj = {};
        obj.id = value.id;
        obj.audience = value.allowPublicAccess
        obj.video_title = value.title;
        obj.video_desc = value.description;
        obj.createdAt = value.createdAt;
        obj.views = value.views;
        obj.poster_image_loc = value.thumbnail
        obj.views = value.videoViews
        obj.duration = utils.formatDuration(value.videoDuration)
        obj.channels = value.channels
        obj.expirationDate = value.expirationDate
        obj.video = value.videoURL
        obj.captions = value.captions
        obj.allowTrainerDownload = value.allowTrainerDownload
        obj.primaryAuthor = value.primaryAuthor
        obj.secondaryAuthor = value.secondaryAuthor
        VideosArray.push(obj)


      }
      setPlaylist(VideosArray.filter(video => video.expirationDate == null))
      // setPlaylist(VideosArray)
      renderthis(true)

    });
  }, [props]);

  const removeVideoDiaglog = (id) => {
    utils.checkMediaPlaylistChannel(id).then(result => {
      setOpen(true);
      console.log(result)
      let res= JSON.parse(result.data.checkForVideoInPlaylistChannel);
      console.log(res)
      setAssociatedList(res)
    })
    setVidID(id)
  }
  const archiveDialog = (id) => {
    setArchiveOpen(true);
    setVidID(id)
  }
  const removeVideo = () => {
    let id = vidID;
    console.log(id)
    utils.DeleteVideoByID(id, associatedList.playlist,  associatedList.channel, 'DELETE').then(result => {
        setOpen(false);
        setPlaylist(Videos.filter(video => video.id != id)); // remove from display
      })
      .catch(err => console.log(err));
  }

  const archiveVideo = () => {
    let id = vidID;
    console.log(id)
    utils.DeleteVideoByID(id,associatedList.playlist, associatedList.channel, "ARCHIVE").then(result => {
        setArchiveOpen(false);
        setPlaylist(Videos.filter(video => video.id != id)); // remove from display
      })
     
  }

  const handleClose = () => {
    //setAnchorEl(null);
    setOpen(false)
  };
  const handleDialogClose = () => {
    setOpen(false);
  };
  const handleArchiveDialogClose = () => {
    setArchiveOpen(false);
  };
  const handleEdit = (id) => {
    props.history.push('/editVideo?v=' + id)
  }
 

  //console.log(Videos[0].views);
  if (finishLoading) {
    console.log(userInfo)
     if (userInfo.role === "Administrator") {
    videoMenu.push({ name: 'Copy Video ID', func: copyVideoID }, { name: 'Archive', func: archiveDialog }, { name: 'Delete', func: removeVideoDiaglog }, )
      }
      else {
        videoMenu.push({ name: 'Copy Video ID', func: copyVideoID })
      }
    return (
      <div class="thumbnailContentBlock">
        <VideoCardRowHeader title={props.title} dest={dest}/>
        {Videos &&
        <div class='videoThumbRow'>
          {Videos.slice(0,5).map(video =>

              <VideoCard
                audience={video.audience}
                activity = {video.createdAt}
                image = {video.poster_image_loc}
                title = {video.video_title}
                age = {Age(video.createdAt)}
                duration = {video.duration}
                views = {video.views||0}
                //{if(video.channel) channel = {video.channel.channel_title} }
                id = {video.id}
                channel ={video.channels}
                 videoMenu={videoMenu}
                 video={video.video}
                 captions={video.captions}
                 allowTrainerDownload={video.allowTrainerDownload}
                   scheduledArchived = {video.expirationDate==null?false:true}
                   primaryAuthor = {video.primaryAuthor}
                   secondaryAuthor = {video.secondaryAuthor}
              />

          )}
        </div>
        }
                     <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"Delete Video"}</DialogTitle>
              <DialogContent>
              {(associatedList.playlist.length == 0 && associatedList.channel.length ==0  ) &&
                  <DialogContentText id="alert-dialog-slide-description">
                  Deleting Videos is permanent! Please confirm this is what you want to do.
                  </DialogContentText>
                 }
                 {(associatedList.playlist.length >0 || associatedList.channel.length >0  )&&
                  <DialogContentText id="alert-dialog-slide-description">
                   Your video/audio is included in {associatedList.playlist.length} playlists and {associatedList.channel.length} channels. Archiving/Deleting this content will remove any reference to the media from playlists and channels. This operation cannot be undone. Do you wish to continue?
                  </DialogContentText>
                 }
              </DialogContent>
              <DialogActions>
                <Button onClick={removeVideo}  variant="contained"
                    size="medium"
                    color="secondary">
                  Yes delete
                </Button>
                <Button onClick={handleDialogClose}  variant="contained"
                    size="medium"
                    color="secondary">
                 Cancel
                </Button>
              </DialogActions>
            </Dialog>
            
             <Dialog
              open={archiveOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleArchiveDialogClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"Archive Video"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                 Are you sure you want to archive this video?
                  Existing references within embedded players may show a deactivated message.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={archiveVideo}  variant="contained"
                    size="medium"
                    color="secondary">
                  Yes archive
                </Button>
                <Button onClick={handleArchiveDialogClose}  variant="contained"
                    size="medium"
                    color="secondary">
                 Cancel
                </Button>
              </DialogActions>
            </Dialog>
      </div>

    )

  }
  else {
    return (<div></div>)
  }
}

export default withRouter(VideoCardRow);
